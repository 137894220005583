<template>
<div>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.photo"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @change="onFileChange"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          {{$t('AccountSetting.Reset')}}
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('AccountSetting.FirstName')"
            label-for="first_name"
          >
            <b-form-input
              v-model="optionsLocal.first_name"
              name="first_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('AccountSetting.LastName')"
            label-for="last_name"
          >
            <b-form-input
              v-model="optionsLocal.last_name"
              name="last_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('AccountSetting.Email')"
            label-for="email"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('AccountSetting.JobRole')"
            label-for="job_role"
          >
            <b-form-input
              v-model="optionsLocal.job_role"
              name="job_role"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            :label="$t('AccountSetting.Phone')"
            label-for="phone_number"
          >
            <the-mask
              class="form-control"
              v-model="optionsLocal.phone_number"
              name="phone_number"
              :mask="['(##) ####-####']"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('AccountSetting.Mobile')"
            label-for="cell_phone_number"
          >
            <the-mask
              class="form-control"
              v-model="optionsLocal.cell_phone_number"
              name="cell_phone_number"
              :mask="['(##) ####-####', '(##) #####-####']"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-if="!sendingForm"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="sendForm"
          >
            {{$t('AccountSetting.Save')}}
          </b-button>
          <b-button v-if="sendingForm"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner small />
            Loading ...
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            {{$t('AccountSetting.Reset')}}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</div>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {TheMask} from 'vue-the-mask'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner,
    TheMask,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      sendingForm: false,
    }
  },
  watch: {
    generalData: function(){
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.optionsLocal.photo = URL.createObjectURL(file);
    },
    sendForm(){
      this.sendingForm = true

      let form = new FormData();
      form.append('first_name', this.optionsLocal.first_name)
      form.append('last_name', this.optionsLocal.last_name)
      form.append('email', this.optionsLocal.email)
      form.append('job_role', this.optionsLocal.job_role)
      this.optionsLocal.phone_number ? form.append('phone_number', this.optionsLocal.phone_number) : ''
      this.optionsLocal.cell_phone_number ? form.append('cell_phone_number', this.optionsLocal.cell_phone_number) : ''
      this.profileFile ? form.append('photo', this.profileFile) : ''
      
      this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/users/update`, form, { headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {  
          this.sendingForm = false
          localStorage.setItem('userData', JSON.stringify(res.data));
          this.showToast('Good job!', 'SmileIcon', 'Account settings updated successfully', 'success')
          return this.$router.push({name: 'home', params: {prefix: this.$router.currentRoute.params.prefix}})
        })
        .catch(error =>{
          this.sendingForm = false
          this.showToast('Error!', 'FrownIcon', 'An error occurred while updating your data!', 'danger')
        })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
  }
}
</script>

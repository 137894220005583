<template>
    <b-card id="profile-change-pin">
        <validation-observer
            ref="passwordForm"
        >
            <!-- form -->
            <b-form>
                <b-row :key="`fields-change-pin-${reRenderFields}`">
                    <!-- new pin -->
                    <b-col md="6">
                        <b-form-group
                            label-for="account-new-pin"
                            :label="$t('AccountSetting.NewPin')"
                        >
                            <validation-provider
                              #default="{ errors }"
                              name="novo pin"
                              vid="account-new-pin"
                              rules="required"
                              :custom-messages="{ required: '0'}"
                            >
            
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input
                                  class="form-control-merge"
                                  id="account-new-pin"
                                  v-model="pin"
                                  :type="pinFieldTypeNew"
                                  name="new-pin"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="$t('AccountSetting.NewPin')"
                                  inputmode="numeric"
                                  @keydown="filterInput($event)"
                                  maxlength=4
                                />
                                <b-input-group-append is-text>
                                  <feather-icon
                                      :icon="passwordToggleIconNew"
                                      class="cursor-pointer"
                                      @click="togglePasswordNew"
                                  />
                                </b-input-group-append>
                            </b-input-group>
            
                            <small class="text-danger">{{ errors[0] == '0' ? $t('AccountSetting.RequiredNewPIN') : '' }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!--/ new pin -->
        
                    <!-- retype pin -->
                    <b-col md="6">
                        <b-form-group
                            label-for="account-retype-new-pin"
                            :label="$t('AccountSetting.RetypeNewPin')"
                        >
                            <validation-provider
                              #default="{ errors }"
                              name="account-retype-new-pin"
                              vid="account-retype-new-pin"
                              rules="required|confirmed:account-new-pin"
                              :custom-messages="{ required: '0',confirmed:'1'}"
                            >
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input
                                  class="form-control-merge"
                                  id="account-retype-new-pin"
                                  v-model="retypePin"
                                  :type="pinFieldTypeRetype"
                                  name="retype-pin"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="$t('AccountSetting.NewPin')"
                                  inputmode="numeric"
                                  @keydown="filterInput($event)"
                                  maxlength=4
                                />
                                <b-input-group-append is-text>
                                  <feather-icon
                                      :icon="passwordToggleIconRetype"
                                      class="cursor-pointer"
                                      @click="togglePasswordRetype"
                                  />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">
                              {{ 
                                errors[0] == '0' ? $t('AccountSetting.RequiredRetypeNewPIN') 
                                : errors[0] == '1' ? $t('AccountSetting.NotMacthPIN') 
                                : ''
                              }}
                            </small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!--/ retype pin -->
        
                    <!-- buttons -->
                    <b-col cols="12" class="col-buttons">
                        <b-button
                            v-if="!sendingForm"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click.prevent="sendForm"
                        >
                            {{ $t('AccountSetting.Save') }}
                        </b-button>
                        <b-button v-if="sendingForm"
                            variant="primary"
                            disabled
                            class="mr-1"
                        >
                            <b-spinner small />
                            {{$t('AccountSetting.Loading')}}...
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-secondary"
                            class="mt-1"
                            @click="clearFields"
                        >
                          {{$t('AccountSetting.Reset')}}
                        </b-button>
                    </b-col>
                    <!--/ buttons -->
                </b-row>
            </b-form>
      </validation-observer>
    </b-card>
</template>
  
<script>
  import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email } from '@validations'
  
  
  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BSpinner,
      BInputGroup,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        sendingForm: false,
        pin:'',
        retypePin:'',
        pinFieldTypeNew: 'password',
        pinFieldTypeRetype: 'password',
        // control render
        reRenderFields: 0,
        // validation rules
        required,
        email,
        
      }
    },
    computed: {
      passwordToggleIconNew() {
        return this.pinFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      passwordToggleIconRetype() {
        return this.pinFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    methods: {
      clearFields(){
        this.pin = '';
        this.retypePin = '';
        this.pinFieldTypeNew = 'password'
        this.pinFieldTypeRetype = 'password';
        this.reRenderFields++;
      },
      filterInput(event){
          let input = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
          let keyPressed = event.key;
          let keyCode = (event.keyCode ? event.keyCode : event.which);
  
          if (!input.includes(keyPressed) && (keyCode != 8)) {
              event.preventDefault()
          }
      },
      togglePasswordNew() {
        this.pinFieldTypeNew = this.pinFieldTypeNew === 'password' ? 'text' : 'password'
      },
      togglePasswordRetype() {
        this.pinFieldTypeRetype = this.pinFieldTypeRetype === 'password' ? 'text' : 'password'
      },
      sendForm(){
        this.sendingForm = true
        this.$refs.passwordForm.validate().then(success => {
          if (success){
            this.$http.post(`api/${this.$router.currentRoute.params.prefix}/users/update`, {pin: this.pin})
              .then(res => {  
                this.sendingForm = false
                this.$swal({
                  title: 'Good job!',
                  text: 'Your data has been successfully updated!',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(() => this.$router.push({name: 'home'}))
              })
              .catch(error =>{
                this.sendingForm = false
                this.$refs.passwordForm.setErrors(error.response.data)
                this.$swal({
                  title: 'Error!',
                  text: error.response.data.message || 'An error occurred while updating your data!',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          } else {
            this.sendingForm = false
          }
        })
      }
    },
  }
</script>

<style lang="scss">
 #profile-change-pin{
  .form-control.is-invalid{
    background-image: none !important;
  }
 }
  .col-buttons{
      margin-top: 14px;
      button{
      margin-top: 0 !important;
      }
  }
</style>
  
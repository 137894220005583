<template>
  <b-card>

    <validation-observer
      ref="passwordForm"
    >
    <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              :label="$t('AccountSetting.OldPassword')"
              label-for="account-old-password"
            >

              <validation-provider
                #default="{ errors }"
                name="old-password"
                vid="v-old-password"
                rules="required"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    class="form-control-merge"
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    placeholder="********"
                    :type="passwordFieldTypeOld"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
              
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="$t('AccountSetting.NewPassword')"
            >
              <validation-provider
                #default="{ errors }"
                name="account-new-password"
                vid="account-new-password"
                rules="required"
              >

                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    class="form-control-merge"
                    id="account-new-password"
                    v-model="newPasswordValue"
                    name="new-password"
                    placeholder="********"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('AccountSetting.ConfirmNewPassword')"
            >
              <validation-provider
                #default="{ errors }"
                name="account-retype-new-password"
                vid="account-retype-new-password"
                rules="required|confirmed:account-new-password"
              >

                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    class="form-control-merge"
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    name="retype-password"
                    placeholder="********"
                    :type="passwordFieldTypeRetype"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-if="!sendingForm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="sendForm"
            >
              {{$t('AccountSetting.Save')}}
            </b-button>
            <b-button v-if="sendingForm"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{$t('AccountSetting.Reset')}}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sendingForm: false,
      
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    sendForm(){
      this.sendingForm = true
      this.$refs.passwordForm.validate().then(success => {
        if (success){
          this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/users/update`, {passwordValueOld: this.passwordValueOld, newPasswordValue: this.newPasswordValue})
            .then(res => {  
              this.showToast('Good job!', 'SmileIcon', 'Account settings updated successfully', 'success')
              return this.$router.push({name: 'home', params: {prefix: this.$router.currentRoute.params.prefix}})
            })
            .catch(error =>{
              this.$refs.passwordForm.setErrors(error.response.data)
              this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
            })
        }
        this.sendingForm = false
      })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
  },
}
</script>
